@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import '../../global.scss';

#BlogAdmin {

    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $preto;

    #mainBlogAdmin {
    
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .tipsBlogAdmin {

            display: flex;
            flex-direction: column;
            margin-top: 8rem;
            text-align: center;

            h1 {

                text-align: center;
                width: 40vw;
                color: $roxo;
                margin-top: 5vh;
                font-size: 3rem;
                animation: glitch1 2s infinite;
                align-self: center;
                margin-bottom: 1rem;
                text-transform: uppercase;
        
            }
        
            h1::before {
                content: " ";
                display: block;
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                inset: 0 0 0 0;
                background: #d4d4d4;
                z-index: -1;
                transition: transform .3s ease;
              }
              
            h1::before {
                transform: scaleX(0);
                transform-origin: bottom right;
            }
        
            h1:hover::before {
                transform: scaleX(1);
                transform-origin: bottom left;
            }

            p {

                padding: .5rem 5rem;
                color: $amarelo;

            }

        }

        form {

            fieldset {
                
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 70vw;
                border: none;
                margin-top: 2rem;

                label {

                    margin: 3rem 0 .3rem 0;
                    font-size: 1.2rem;
                    font-weight: 500;
                    color: $branco;
                    
                }

                input {

                    width: 90%;
                    height: 3rem;
                    font-size: 1rem;
                    border-radius: 3px;
                    border: none;
                    outline: none;
                    padding-left: 1rem;
                    color: $amarelo;
                    background-color: $roxo;

                    &::selection {

                        background-color: #025fce;
    
                    }

                }

                input[type='file'] {

                    height: auto;
                    padding: .5rem 0 .5rem 1rem;

                }

                .btnAddHashtag {
                    
                    display: flex;
                    margin-top: 1rem;
                    background-color: $amarelo;
                    color: $roxo;
                    padding: 5px 10px;
                    border-radius: 3px;
                    font-weight: 700;
                    cursor: pointer;

                }

                .createdHashtags {

                    display: flex;
                    flex-wrap: wrap;
                    width: 90%;
                    margin-top: 1rem;

                    h4 {

                        background-color: $roxo;
                        color: $amarelo;
                        font-weight: 700;
                        margin: 3px 5px;
                        padding: 1px 15px;
                        border-radius: 4px;

                    }

                }

                textarea {

                    height: 500px;
                    width: 90%;
                    color: $amarelo;
                    padding: 10px;
                    border-radius: 5px;
                    margin-bottom: 5vh;
                    outline: none;
                    font-size: 1rem;
                    border: none;
                    background-color: $roxo;

                    &::selection {

                        background-color: #025fce;
    
                    }
                    
                }

            }

        }

    }

    #defaultSectionAdmin {

        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h2 {

            margin-bottom: 1rem;
            color: $branco;

        }

        select {

            width: 50%;
            border-radius: 5px;
            background-color: $roxo;
            border: none;
            margin: 0 0px 25px 0px;
            padding: 16px 24px;
            font-size: 14px;
            color: #DBB826;
            outline: none;

        }

    }
    
}

    .sendButtonBlog {

        cursor: pointer;
        padding: 1rem 3rem;
        background-color: #DBB826;
        border-radius: 8px;
        font-size: 10pt;
        margin-bottom: 10vh;
        font-size: 1rem;
        color: $branco;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {

            opacity: .9;

        }
    }

#BlogLogin {

    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #000;
    position: relative;

    .leftSideBlogLogin {

        display: flex;
        width: 70%;
        height: 100%;
        font-family: 'Montserrat', 'sans-serif';
        flex-direction: column;
        align-items: center;
        background-color: $preto;
        justify-content: center;

        .leftSideContentWrapper {

            display: flex;
            align-items: left;
            justify-content: left;
            margin: 0 auto;
            padding: 0;
            position: relative;
            width: 100%;
            height: 100%;

            #paperImg {

                display: flex;
                position: absolute;
                width: 112%;
                height: 100vh;
                z-index: 999;

            }

            .loginForms {

                display: flex;
                position: absolute;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                padding: 0;
                width: 55%;
                height: 100%;
                left: 0;
                z-index: 1000;

                h1 {

                    font-size: 2rem;

                }

                p {

                    padding: 1rem;
                    text-align: center;

                    a {

                        text-decoration: none;
                        font-weight: 500;

                        &:hover {

                            color:rgb(0, 0, 0);

                        }

                    }
                }

            }
            
            input {
            
                padding: .5rem 2rem .5rem 1rem;
                margin: .3rem 0;
                font-size: 17px;
                border-radius: 5px;
                border: 1.5px solid #e9e9e9;
                background-color: none;
                outline: none;

                &:focus {

                    border: 1px solid $roxo;

                }

            }
            
            input::placeholder {
            
                color: rgb(114, 114, 114);
                font-size: .9rem;
            
            }
            
            #signInButton {

                text-align: center;
                background-color: $roxo;
                cursor: pointer;
                font-size: 18px;
                margin: 1rem 0;
                border-radius: 8px;
                width: 10rem;
                padding: .7rem;
                font-weight: 700;
                color: $branco;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {

                    opacity: .9;

                }

            }

            #returnButton {

                text-decoration: none;
                font-weight: 500;
                color: $preto;

            }
    
            img {
            
                width: 250px;
            
            }

        }
    }

    .rightSideBlogLogin {

        display: flex;
        width: 30%;
        height: 100%;
        position: relative;
        right: 0;
        
        .blogLoginImgWrapper {

            #paperImg {

                position: absolute;
                width: 50%;
                height: 100vh;

            }
            
            #collage {
                
                position: absolute;
                width: auto;
                height: 100%;
                margin: 0 auto;
                padding: 0;
                right: 0;
                z-index: 998;

            }

        }

    }

}

@media screen and (max-width: 768px) {

    #BlogAdmin {

        width: auto;
        // display: flex;
        // align-items: center;
        // flex-direction: column;
        // justify-content: center;
        // background-color: $preto;
    
        #mainBlogAdmin {
        
            width: auto;
            // display: flex;
            // align-items: center;
            // flex-direction: column;
            // justify-content: center;
    
            .tipsBlogAdmin {
    
                display: flex;
                flex-direction: column;
                margin-top: 8rem;
                text-align: center;
    
                h1 {
    
                    text-align: center;
                    width: 80vw;
                    color: $roxo;
                    margin-top: 5vh;
                    font-size: 1.6rem;
                    animation: glitch1 2s infinite;
                    align-self: center;
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                    background-color: #d4d4d4;
            
                }
    
                p {
    
                    padding: .5rem 5rem;
                    color: $amarelo;
    
                }
    
            }
    
            form {
    
                fieldset {
                    
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 70vw;
                    border: none;
                    margin-top: 2rem;
    
                    label {
    
                        margin: 3rem 0 .3rem 0;
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: $branco;
                        
                    }
    
                    input {
    
                        width: 90%;
                        height: 3rem;
                        font-size: 1rem;
                        border-radius: 3px;
                        border: none;
                        outline: none;
                        padding-left: 1rem;
                        color: $amarelo;
                        background-color: $roxo;
    
                        &::selection {
    
                            background-color: #025fce;
        
                        }
    
                    }
    
                    input[type='file'] {
    
                        height: auto;
                        padding: .5rem 0 .5rem 1rem;
    
                    }
    
                    textarea {
    
                        height: 500px;
                        width: 90%;
                        color: $amarelo;
                        padding: 10px;
                        border-radius: 5px;
                        margin-bottom: 5vh;
                        outline: none;
                        font-size: 1rem;
                        border: none;
                        background-color: $roxo;
    
                        &::selection {
    
                            background-color: #025fce;
        
                        }
                        
                    }
    
                }
    
            }
    
        }
    
        #defaultSectionAdmin {
    
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
    
            h2 {
    
                margin-bottom: 1rem;
                color: $branco;
    
            }
    
            select {
    
                width: 50%;
                border-radius: 5px;
                background-color: $roxo;
                border: none;
                margin: 0 0px 25px 0px;
                padding: 16px 24px;
                font-size: 14px;
                color: #DBB826;
                outline: none;
    
            }
    
        }
        
    }
    
        .sendButtonBlog {
    
            cursor: pointer;
            padding: 1rem 3rem;
            background-color: #DBB826;
            border-radius: 8px;
            font-size: 10pt;
            margin-bottom: 10vh;
            font-size: 1rem;
            color: $branco;
            font-weight: 700;
            text-transform: uppercase;
    
            &:hover {
    
                opacity: .9;
    
            }
        }
    
    #BlogLogin {
    
        width: 100vw;
        height: 100vh;
        position: relative;
    
        .leftSideBlogLogin {
    
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
//            background-image: url("../../imgs/loginMobile.png");
            background-repeat: no-repeat;

            .leftSideContentWrapper {
    
                width: 100%;
                height: 100%;
    
                #paperImg {
    
                    display: none;
    
                }
    
                .loginForms {
    
                    display: flex;
                    position: static;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    padding: 0;
                    width: 55%;
                    height: 100%;
                    left: 0;
                    z-index: 1000;
                    // background-color: red;
    
                    h1 {
    
                        font-size: 2rem;
                        width: 160%;
                        color: $branco;
                        text-align: center;
                        font-size: 1.5rem;
                        margin-bottom: 2rem;
    
                    }
    
                    p {
    
                        padding: 0rem;
                        text-align: center;
                        color: $branco;
                        width: 160%;
                        margin-bottom: 4rem;
    
                        a {
    
                            text-decoration: none;
                            font-weight: 500;
    
                            &:hover {
    
                                color:rgb(0, 0, 0);
    
                            }
    
                        }

                    }

                    #returnButton {

                        color: $branco;

                    }
    
                }
                
                input {
                
                    padding: .5rem 2rem .5rem 1rem;
                    margin: .3rem 0;
                    font-size: 17px;
                    border-radius: 5px;
                    border: 1.5px solid #e9e9e9;
                    background-color: none;
                    outline: none;
    
                    &:focus {
    
                        border: 1px solid $roxo;
    
                    }
    
                }
                
                input::placeholder {
                
                    color: rgb(114, 114, 114);
                    font-size: .9rem;
                
                }
                
                #signInButton {
    
                    text-align: center;
                    background-color: $roxo;
                    cursor: pointer;
                    font-size: 18px;
                    margin: 1rem 0;
                    border-radius: 8px;
                    width: 10rem;
                    padding: .7rem;
                    font-weight: 700;
                    color: $branco;
                    text-decoration: none;
                    text-transform: uppercase;
    
                    &:hover {
    
                        opacity: .9;
    
                    }
    
                }
    
                #returnButton {
    
                    text-decoration: none;
                    font-weight: 500;
                    color: $preto;
    
                }
        
                img {
                
                    width: 250px;
                
                }
    
            }
        }
    
        .rightSideBlogLogin {
    
            display: none;
            width: 0%;
            height: 0%;
            position: relative;
            right: 0;
            
            .blogLoginImgWrapper {
    
                #paperImg {
    
                    position: absolute;
                    width: 50%;
                    height: 100vh;
                    display:none;
    
                }
                
                #collage {
                    
                    position: absolute;
                    width: auto;
                    height: 100%;
                    margin: 0 auto;
                    padding: 0;
                    right: 0;
                    z-index: 998;
                    display:none;
    
                }
    
            }
    
        }
    
    }

}