@import "../../global.scss";

#product {

    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;

    body {

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        #selectedProductSection {

            display: flex;
            justify-content: space-around;
            width: 90%;
            margin-top: 20vh;
            min-height: 80vh;
    
            .productImageWrapper {
    
                width: 30vw;
                height: 30vw;
    
                img {
                    object-fit: cover;
                    width: 100%;
                    max-height: 100%;
                }
    
            }

            .productInfosWrapper {

                width: 60%;
                height: 100%;

                padding: 0 2rem;

                color: #fff;

                .productSpecifications {

                    margin-top: 2rem;

                    ul {

                        list-style: none;
                        margin: 1rem 0;

                    }

                    li {

                        margin: 5px 0;

                    }

                }

                .productButtons {

                    display: grid;
                    grid-template-columns: repeat(3, 30px);
                    grid-template-rows: 1fr;
                    grid-column-gap: 15px;
                    grid-row-gap: 0px;
                    margin-top: 1rem;

                    text-align: center;

                    .btnWrapper {

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        background-color: $light-green;
                        border-radius: 100%;
                        cursor: pointer;

                        h3 {
                            padding: .5rem;
                            font-weight: 700;
                            user-select: none;
                        }

                    }

                }

                .finishBuy {

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 2rem;

                    h3 {
                        color: #fff;
                        background-color: $dark-green;
                        padding: 5px 15px;
                        border-radius: 15px;
                    }

                    button {
                        margin: 0;
                        padding: 5px 15px;
                        border-radius: 15px;
                        text-transform: uppercase;
                        background-color: $dark-green;
                        color: #fff;
                        cursor: pointer;
                        outline: none;
                        border: none;
                        font-weight: 700;
                        font-size: 1rem;
    
                        &:hover {
                            background-color: $orange;
                        }
                    }

                }

            }
    
        }
    }
    
}

@media screen and (max-width: 768px) {

    #product {

        body {
    
            #selectedProductSection {
    
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 90%;
        
                .productImageWrapper {
        
                    width: 50vw;
                    height: 50vw;
        
                    img {
                        object-fit: cover;
                        width: 100%;
                        max-height: 100%;
                    }
        
                }
    
                .productInfosWrapper {
                    
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 2rem;
                    width: 100%;
                    text-align: center;
    
                    .productSpecifications {
    
                        margin-top: 2rem;
    
                        ul {
    
                            list-style: none;
                            margin: 1rem 0;
    
                        }
    
                        li {
    
                            margin: 5px 0;
    
                        }
    
                    }
    
                    .finishBuy {
    
                        flex-direction: column;
                        margin: 2rem 0;
    
                        h3 {
                            margin-bottom: 1rem;
                        }
    
                    }
    
                }
        
            }
        }
        
    }

}
