@import "../../global.scss";

#registerProduct {

    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;

    body {

        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        width: 100%;

        #searchSection {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100vw;
            margin-top: 20vh;
            
            h1 {

                font-family: 'Bebas Neue', sans-serif;
                font-size: 2.5rem;
                color: $light-green;
                text-shadow: 2px 1px 3px rgba(29, 29, 29, 0.5);
                letter-spacing: .3rem;
                text-align: center;

            }

            h3 {

                margin: 2rem 0 .5rem 0;
                color: #fff;
                background-color: $orange;
                padding: 5px 15px;
                border-radius: 15px;

            }

            .search {

                display: flex;
                align-items: center;
                justify-content: center;
                width: 30%;

                input {

                    width: 100%;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    padding: 8px 5px;
                    color: #000;

                    &::placeholder{
                        color: rgb(58, 58, 58);
                    }
                }

            }

            .buttonSearch {

                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    padding: 5px 25px;
                    border-radius: 8px;
                    background-color: $orange;
                    color: #fff;
                    font-weight: 700;
                    outline: none;
                    border: none;
                    width: 100%;
                    cursor: pointer;
                }

            }

            span {

                margin: 1rem 3rem;
                text-align: center;
                font-weight: 500;
                font-size: 1.05rem;
                color: #fff;

                a {

                    text-decoration: none;
                    color: $yellow-green;

                }


            }

        }
        
        #productsSection {

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            // grid-template-columns: repeat(4, 1fr);
            // grid-template-rows: repeat(auto-fill, 1fr);
            // grid-column-gap: 50px;
            // grid-row-gap: 30px;

            width: 90%;
            padding: 0 2rem;

            .productWrapper {

                cursor: pointer;
                transition: all 0.2s ease-in-out;
                text-decoration: none;
                width: 230px;
                margin: 2rem 2.5%;

                .productImgWrapper {

                    display: flex;
                    width: 100%;
                    height: 70%;

                    img {
                        object-fit: cover;
                        max-height: 100%;
                        width: 100%;
                    }

                }

                .productInfos {

                    width: 100%;
                    height: 30%;
                    padding: 6px;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;

                    font-family: "Roboto", Sans-serif;

                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;

                    color: #fff;

                    span {

                        margin: 1rem 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                }

                &:hover {

                    transform: translateY(-15px);
                    transition: all 0.4s ease-in-out cubic-bezier(0.175, 0.885, 0.4, 1.275);

                }

            }

        }

    }

}

@media screen and (max-width: 768px) {

    #registerProduct {
    
        body {
    
            #searchSection {
                
                .search {
    
                    width: 50%;
    
                }
    
                .buttonSearch {
    
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                }
    
            }
            
            #productsSection {
    
                .productWrapper {
    
                    width: 170px;
                    margin: 1rem 2.5%;
                    height: 100%;
    
                    .productImgWrapper {
    
                        display: flex;
                        width: 100%;
                        height: 30%;
    
                        img {
                            object-fit: cover;
                            max-height: 100%;
                            width: 100%;
                        }
    
                    }
    
                }
    
            }
    
        }
    
    }

}