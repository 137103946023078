@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import '../../../global.scss';

* {

    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;

}

main {

    width: 100vw;
    display: flex;
    flex-direction: column;
    background: linear-gradient(45deg, #72A67F, #24733F);

    #ColorSection {

        display: flex;
        margin-top: 8rem;
        flex-direction: column;
        align-items: center;
        color: #fff;

        h1 {

            margin: 1rem 0;
            padding: .3rem 1rem;
            background-color: $orange;
            border-radius: 100px;

        }

        .formatType {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            width: 40%;
            background-color: $light-green;
            padding: 2rem 0 1rem 0;
            margin-bottom: 2rem;
            border-radius: 5px;

            input {

                display: flex;
                margin: 0;
                width: 60%;

            }

            h2 {

                margin: 1rem 0;
                background-color: $orange;
                padding: .2rem 1rem;
                border-radius: 100px;

            }

        }

        input[type=text], #ilustresPrice{

            margin-bottom: 2rem;
            width: 50%;
            height: 40px;
            padding: .7rem .5rem;
            border-radius: 5px;

            border: 1.7px solid #e8e8e8;

        }

        button {

            padding: .7rem 2rem;
            margin-bottom: 2rem;
            background-color: $orange;
            border: none;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            border-radius: 5px;
            transition: .2s ease-in-out;
    
            &:hover {
    
                background-color: $light-orange;
    
            }
    
        }

        .checkboxSelector {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h3 {

                margin-top: 1rem;

            }

            .checkboxWrapper {

                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Ajuste conforme necessário */
                gap: 1rem;
                background-color: $light-green;
                margin: 1rem 0;
                width: 60vw;
                align-items: center;
                padding: 1rem 0;
                border-radius: 5px;

                .checkbox {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;

                    input {
        
                        width: auto;
                        height: auto;
                        margin: 0;

                    }

                    label {

                        margin-left: 5px;

                    }

                }

            }

        }

        #ilustresData{
            select {
            
                    margin: .2rem .2rem;
                    padding: .7rem 0;
                    border-radius: 5px;
                    width: 50%;
            
                    border: 1.7px solid #e8e8e8;
                    outline-color: $orange;
            
                }
        }

    }

    #deleteColorSection {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;

        fieldset {

            display: flex;
            align-items: center;
            flex-direction: column;
            outline: none;
            border: none;
            text-align: center;
            width: 100%;

           legend h1 {

                margin: 1rem 0;
                padding: .3rem 1rem;
                background-color: $orange;
                color: #fff;
                border-radius: 100px;
    
            }

            select {

                margin: .2rem .2rem;
                padding: .7rem 0;
                border-radius: 5px;
                width: 30%;

                border: 1.7px solid #e8e8e8;
                outline-color: $orange;

            }

            button {

                padding: .7rem 2rem;
                margin: 1rem 0 2rem;
                background-color: $orange;
                border: none;
                cursor: pointer;
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                border-radius: 5px;
                transition: .2s ease-in-out;
        
                &:hover {
        
                    background-color: $light-orange;
        
                }
            }

        }

    }

}

@media screen and (max-width: 768px) {

    main {
    
        #ColorSection {
            
            text-align: center;
    
            .formatType {
    
                width: 70%;

            }
    
            input:nth-child(1)[type=text] {
    
                width: 80%;
            
            }
    
            .checkboxSelector {

                h3 {

                    width: 80%;

                }
    
                .checkboxWrapper {
    
                    width: auto;
                    padding: 1rem 4rem;
                    flex-direction: column;
                    align-items: flex-start;
    
                }
    
            }
            #ilustresData{
                select, #ilustresPrice {
                    width: 100%;            
                    }
                }   
    
        }
    
    }

}
