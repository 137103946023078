@import "../../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

* {

    font-family: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;

}

#MainBlog {

    background: $offWhite !important;
    
    #PostAuthorSection {
      
        display: flex;
        width: 100vw;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;

        .textIntroWrapper {

          width: 80%;
          margin-top: 6rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          h1 {

            letter-spacing: .7px;
            font-size: 2.625rem;
            line-height: 1.3;
            font-weight: 700;
            font-family: "Source Sans Pro", sans-serif;
            color: #3d3d3d;
            margin: 1rem 0 1.5rem 0;
            
          }

          span {

            font-size: 15px;

          }

          p {

            margin-bottom: 2rem;
            
          }

          ul {

            display: flex;
            flex-wrap: wrap;
            width: 70%;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            li {

              list-style: none;
              margin: 0 1rem;
              background-color: #dfdfdf;
              padding: 5px 15px;
              border-radius: 3px;
              font-weight: 500;
              font-family: "Poppins", sans-serif;

            }
            
          }

        }

        .authorInfos {

          width: 70%;
          height: 200px;
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          justify-content: center;

          .authorImgWrapper {

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            background-color: rgb(255, 255, 255);
            padding: 5px;
            border-radius: 200px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  
            img {
  
              width: 100%;
              height: 100%;
              border-radius: 200px;
              object-fit: cover;
              
            }
  
          }

          .authorSocials {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            height: 50%;

            h1 {

              margin: 0 2rem;
              font-size: 1.5rem;
              font-family: "Poppins", sans-serif;
  
            }
  
            .socialMediaWrapper {
  
              display: flex;
              padding: 0 2rem;
    
              .socialMedias {
    
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                margin: 0 10px;
                padding: 5px;
                background-color: rgb(255, 255, 255);
                border-radius: 200px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                cursor: pointer;
      
                #socialMediaLink {
    
                  align-items: center;
                  display: flex;
                  justify-content: center;
    
                  img {
      
                    width: 80%;
                    object-fit: cover;
        
                  }
    
                } 
      
              }
    
            }

          }

        }

        .postImgWrapper {

          display: flex;
          width: 70%;

          img {

            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            background-size: contain;
            
          }

        }

    }

    #PostTextSection {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100vw;
      margin-top: 1rem;

      p {

        font-family: "Whitney SSm A", "Whitney SSm B", Helvetica, "Helvetica Neue", Arial, sans-serif;
        margin: 2rem 0;
        width: 70%;
        letter-spacing: .3px;
        line-height: 1.6;
        font-size: 1.20rem;
        margin-bottom: 30px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        text-rendering: optimizelegibility;
        word-break: break-word;
        color: #3d3d3d;
        text-align: justify;
        text-indent: 2em;
        white-space: pre-line;
        
      }

    }

}

@media screen and (max-width: 768px) {

  #MainBlog {
    // background: linear-gradient(45deg, $light-green, $dark-green) !important;

    #PostAuthorSection {

      .textIntroWrapper {
        ul {
          li {
            margin: .45rem 1rem;
          }
        }
      }

      .authorInfos {

        width: 90%;
        text-align: center;
        height: auto;
        margin: 1rem 0;

        .authorSocials {

          h1 {
            margin: 0.4rem 1.5rem;
            font-size: 20px;
          }

        }

      }

    }

    #PostTextSection p {

      width: 80%;
      text-align: left;

    }

  }

}