@import "../../global.scss";

.requestsPage {

    display: flex;
    flex-direction: column;
    width: 100vw;
    background: linear-gradient(45deg, $light-green, $dark-green);
    min-height: 100vh;

    #DataSection {

        margin-top: 8rem;

        h1 {

            font-family: 'Bebas Neue', sans-serif;
            font-size: 2.5rem;
            color: $light-green;
            text-shadow: 2px 1px 3px rgba(29, 29, 29, 0.5);
            letter-spacing: .3rem;
            text-align: center;

        }

        .requestData {

            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            width: 100vw;

            .boxInfoData {

                display: flex;
                flex-direction: column;
                align-items: center;
                width: 80%;
                padding: 1rem .2rem;
                background-color: $light-green;
                border-radius: 5px;
                margin: 2rem 0;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                .rowData {

                    display: flex;
                    width: 90%;
                    justify-content: space-between;
                    margin: 10px 0;
                    background-color: $orange;
                    padding: 10px 3px;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    border-radius: 50px;
                    
                }

                #pixProofDiv, #noteDiv {

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 0px;
                    justify-content: center;
                    width: auto;
                    border-radius: 3px;
                    width: 50%;
                    align-self: center;

                    h4 {

                        margin-bottom: 1rem;
                        text-align: center;

                    }

                    .paymentProof {

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 1rem 0;

                        label {

                            color: #fff;
                            font-weight: 700;

                        }

                        p {

                            color: #fff;
                            margin: 1rem;
                            font-weight: 700;
                            text-align: center;

                        }

                    }

                    button {

                        margin: 1rem 0 0;
                        background-color: $dark-green;
                        color: $branco;
                        padding: .5rem 2rem;
                        transition: transform .45s ease, background .15s ease;
                        cursor: pointer;
                        border: none;
                        border-radius: 5px;
        
                        &:hover {
        
                            background-color: $light-green;
        
                        }
        
                    }

                }

                #noteDiv {

                    p {

                        word-break: break-word;
                        width: 90%;
                        text-align: center;

                    }

                }

                h4 {

                    color: $branco;
                    padding: 0 5px;

                }

                .boxProductInfos {

                    display: flex;
                    flex-wrap: wrap;
                    margin: 5px 0;
                    width: 100%;
                    justify-content: center;

                    .productInfo {

                        display: flex;
                        text-align: center;
                        width: 45%;
                        margin: 5px;
                        background-color: $dark-green;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        list-style: none;
                        padding: 5px;
                        border-radius: 5px;
                        color: #fff;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                        h2 {

                            background-color: $light-orange;
                            padding: 5px 10px;
                            border-radius: 100px;
                            margin: 1rem 0;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                        }

                        li {

                            margin: 15px 0;

                        }

                        .coverColorDiv {

                            display: flex;

                        }

                    }

                }

            }

            button {

                margin: 2rem 0;
                background-color: $orange;
                color: $branco;
                padding: 1rem 4rem;
                transition: transform .45s ease, background .15s ease;
                cursor: pointer;
                border: none;
                border-radius: 5px;

                &:hover {

                    background-color: $offWhite;
                    color: $orange;
                    transition: transform .45s ease, background .15s ease;

                }

            }

        }

    }

}

@media screen and (max-width: 768px) {

    .requestsPage {
    
        #DataSection {
    
            .requestData {
    
                .boxInfoData {

                    .rowData {

                        text-align: center;
                        flex-direction: column;

                        h4:nth-child(2) {

                            font-weight: 400;

                        }

                    }
    
                    #pixProofDiv, #noteDiv {
                
                        width: 90%;

                        .paymentProof {

                            input {

                                width: 100%;
                                text-align: center;
                                margin-top: 1rem;

                            }

                        }

                    }
    
                    .boxProductInfos {
    
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
    
                        .productInfo {
    
                            width: 90%;
    
                        }
    
                    }
    
                }
    
            }
    
        }
    
    }

}
