@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

$branco: #fff;
$preto: #000;
$dark-green: #24733f;
$light-green: #72a67f;
$light-blue: #a4bfab;
$orange: #de7440;
$light-orange: #ff874b;
$yellow-green: #ded040;
$offWhite: #f2f2f2;
$offBlack: rgb(34, 34, 34);
$roxo: #5508ff;
$amarelo: #ffff00;

:root {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

// ::selection {
//     background-color: $light-orange;
// }

::-webkit-scrollbar {
    background: $offWhite;
}

::-webkit-scrollbar-thumb {
    background-color: $light-blue;
}

#MainSketchbook {
    position: relative;

    .divModal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 3;

        .modalContent {
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50vw;
            height: 60vh;
            background-color: $offWhite;
            top: 25%;
            left: 25%;
            border-radius: 8px;
            padding: 1rem;

            span {
                display: flex;
                width: 35px;
                height: 35px;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
                top: 10px;
                padding: 10px;
                background-color: $orange;
                border-radius: 50px;
                cursor: pointer;
                font-weight: 700;
                font-size: 20px;
            }

            .sketchbookImgWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                width: 50%;

                h3 {
                    font-size: 1rem;
                }

                img {
                    object-fit: cover;
                    width: 50%;
                    overflow: hidden;
                    margin-top: 1rem;
                }
            }
        }

        #facheiroVersion {
            flex-wrap: wrap;
            width: 50vw;
            height: 80vh;
            background-color: $offWhite;
            top: 10vh;
            left: 25%;
        }

        .hide {
            display: none;
        }
    }

    #CreateSketchbookSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        margin-top: 10rem;
        margin-bottom: 5rem;

        p {
            color: $offWhite;
        }

        label,
        .upperCaseText {
            text-transform: uppercase;
        }

        .logoWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 250px;
            height: 250px;
            padding: 5px;
            margin-bottom: 1rem;

            img {

                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;

            }

        }

        .textIntro {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
            margin-bottom: 2.5rem;
            color: $offWhite;

            h5 {
                font-size: 1rem;
                font-weight: 200;
                margin-top: 0.5rem;
            }
        }

        fieldset {
            display: flex;
            flex-direction: column;
            border: none;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-weight: 500;

            label {
                font-size: 1rem;
                margin-bottom: 0.35rem;
                color: $offWhite;
            }
        }

        select {
            margin-bottom: 2rem;
            width: 50%;
            background-color: $light-green;
            color: $offWhite;
            font-size: 1.02rem;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            outline: none;

            option {
                border: none;
            }
        }

        p a {
            text-decoration: none;
            color: $yellow-green;
        }

        button {
            padding: 0.7rem 2rem;
            margin-bottom: 2rem;
            background-color: $orange;
            border: none;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            border-radius: 5px;
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: $light-orange;
            }
        }

        .textWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 2rem 0;
            text-align: center;

            .textBackground {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $light-green;
                color: #fff;
                margin-bottom: 10px;
                padding: 0.7rem 1.5rem;
                border-radius: 25px;
            }

            p {
                font-weight: 500;
                margin: 0.5rem 0;
                padding: 0 0.5rem;
                text-align: center;
                text-transform: uppercase;
            }

            button {
                padding: 0;
                margin: 0;
                font-weight: 500;
                color: $yellow-green;
                background-color: transparent;
            }

            img {
                width: 40%;
                border-radius: 25px;
            }
        }

        .sliderColors {
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 85vw;
            height: 30vw;

            button {
                display: none !important;
            }

            div.slick-list {
                padding: 1rem 0;
                -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.57);
                box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.57);
                border: 1px solid $dark-green;
                border-radius: 5px;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                cursor: grab;

                &:active {
                    cursor: grabbing;
                }

                .cardColor {
                    display: flex !important;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    word-wrap: break-word;
                    line-height: 1rem;
                    height: 100%;
                    margin: 0 0.7rem;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
                    background-color: $offWhite;
                    padding: 10px;
                    text-align: center;
                    position: relative;

                    label {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        cursor: grab;
                        opacity: 0.2;

                        &:active {
                            cursor: grabbing;
                        }
                    }

                    .colorBox {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px 3px 0 0;
                        height: 75%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            text-transform: uppercase;
                            font-weight: 700;
                            color: rgb(65, 65, 65);
                            background-color: rgb(223, 223, 223);
                            padding: 0.2rem 0.6rem;
                            border-radius: 50px;
                        }
                    }

                    .colorName {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        height: 40%;
                        border-radius: 0 0 3px 3px;

                        p {
                            font-family: 'Poppins', sans-serif;
                            color: rgb(65, 65, 65);
                            font-weight: 600;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        #RadioSelectionColors,
        #RadioSelectionLineColor {
            display: flex;
            width: 100%;
            margin-bottom: 0.5rem;
            align-items: center;
            justify-content: space-evenly;

            .boxColor {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .lineColorWrapper,
                .elasticColorWrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40vw;
                    flex-wrap: wrap;
                    background-color: $offWhite;
                    border-radius: 8px;
                    padding: 1rem;

                    .colorWrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .lineColor,
                        .elasticColor {
                            display: flex;
                            width: 50px;
                            height: 50px;
                            border-radius: 50px;
                            margin: 10px 15px 2px 15px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50px;
                                object-fit: cover;
                            }
                        }
                    }
                }

                .coverColorWrapper {
                    display: flex;
                    width: 90vw;
                }
            }
        }

        .additionalInfos {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;

            label {
                font-weight: 500;
                margin-bottom: 1rem;
                color: $offWhite;
            }

            textarea {
                height: 200px;
                width: 50%;
                padding: 10px;
                border-radius: 5px;
                margin-bottom: 2rem;
                outline: none;
                font-size: 1rem;
                border: none;
                background-color: $dark-green;
                color: $branco;
            }

            p {
                background: rgba(222, 208, 64, 0.25);
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.24);
                backdrop-filter: blur(4px);
                -webkit-backdrop-filter: blur(4px);
                border-radius: 5px;
                border: 1px solid rgba(255, 255, 255, 0.18);
                // border-radius: 5px;
                // margin-bottom: 1rem;
                // background-color: $yellow-green;
                // //color: $offBlack;
                margin-bottom: 1rem;
                padding: 1rem;
            }

            .productInfosWrapper {
                // margin-bottom: 2rem;
                // padding: 1rem 0;
                // border-radius: 10px;
                // background: #fff;

                width: 50%;
                background: rgba(255, 255, 255, 0.15);
                backdrop-filter: blur(4px);
                -webkit-backdrop-filter: blur(4px);
                border-radius: 10px;
                border: 1px solid rgba(255, 255, 255, 0.18);
                color: $offWhite;
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.24);
                margin-bottom: 2rem;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                h1 {
                    border-bottom: 2px solid $orange;
                }

                ul {
                    list-style: none;
                    width: 75%;

                    li {
                        padding: 1rem 20px;
                        margin: 20px 0;

                        background-color: $orange;
                        border-radius: 5px;
                    }
                }

                h3 {
                    margin: 2rem 0 1rem 0;
                }

                button {
                    margin: 0;
                    border-radius: 100px;
                    text-transform: uppercase;
                    background-color: $dark-green;

                    &:hover {
                        background-color: $orange;
                    }
                }
            }
        }

        #paperWarning {
            color: #fff;
            margin: 1rem;
            line-height: 2;

        }

        .emphasisWarning {
            background: #72a67f;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.24);
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(4px);
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, 0.18);
            margin-bottom: 1rem;
            padding: 0.5rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }

        #steps-indicator {
            margin: 0 3rem;
            width: 80%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            gap: 0.5rem;
        }

        .stepTitle {
            padding: 10px 20px;
            border-radius: 10px;
            background: $orange;
            color: $offBlack;
        }

        .active-step {
            background: $yellow-green;
        }

        .hide {
            display: none;
        }

        .full {
            width: 100%;
        }

        #btns {
            margin-top: 4rem;

            .btn {
                margin-left: 2rem;
                margin-right: 2rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #MainSketchbook {
        .divModal {
            .modalContent {
                height: auto;
                min-height: 60vh;
                width: 90%;
                left: 5%;
                right: 5%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .sketchbookImgWrapper {
                    margin-bottom: 0.5rem;
                    width: 100%;

                    h3 {
                        font-size: 1rem;
                    }

                    img {
                        width: 25%;
                    }
                }

                span {
                    width: 25px;
                    height: 25px;
                    font-size: 16px;
                }
            }

            #facheiroVersion {
                width: 70vw;
                left: 12.5vw;
                right: 12.5vw;
            }
        }

        #CreateSketchbookSection {
            padding: 0 2rem !important;
            text-align: center;

            select {
                width: 70%;
            }

            .iframe-container {
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                height: 0;
            }
            
            .iframe-container iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .sliderColors {
                div.slick-list {
                    padding: 0;
                    border: 1px solid $dark-green;
                    border-radius: 5px;
                    width: 100%;
                    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.57);
                    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.57);

                    div.slick-slide {
                        margin: 0 0.2rem;
                        width: 90vw !important;
                        height: 80%;
                    }
                }

                min-height: 25vh;
                height: 60vw;

                div .cardColor {
                    margin: 0;

                    img {
                        width: 20vw !important;
                    }

                    .colorBox {
                        p {
                            font-size: 0.6rem;
                            padding: 0.2rem;
                            margin: 1rem;
                        }
                    }

                    .colorName {
                        p {
                            margin: 0.6rem 0 0.3rem;
                            width: 100%;
                            word-wrap: break-word;
                            font-size: 0.7rem;
                            line-height: 0.7rem;
                        }

                        input {
                            margin-bottom: 0.4rem;
                        }
                    }
                }
            }

            .textWrapper .textBackground {
                h2 {
                    font-size: 1.2rem;
                }
            }
            
            .textWrapper {
                img {
                    width: 95%;
                    border-radius: 15px;
                }
            }

            .additionalInfos {
                textarea {
                    width: 100%;
                }

                .productInfosWrapper {
                    width: 100%;

                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        li {
                            width: 100%;
                        }
                    }

                    h3 {
                        margin-top: 1rem;
                    }
                }
            }

            #RadioSelectionColors,
            #RadioSelectionLineColor {
                flex-direction: column;

                h2 {
                    font-size: 1.2rem;
                }

                .boxColor {

                    .lineColorWrapper,
                    .elasticColorWrapper {
                        width: 80vw;
                    }
                }
            }

            #steps-indicator {
                width: 100%;
            }
        }
    }
}