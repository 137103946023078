@import "../../global.scss";

* {
    font-family: 'Urbanist', sans-serif;
}

h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

#mainHome {

    display: flex;
    flex-direction: column;
    width: 100vw;
    background: linear-gradient(45deg, $orange, $light-orange);
    position: relative;
    
    .divModal {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 3;

        .modalContent {

            position: fixed;
            display: flex;
            align-items: center;
            width: 50vw;
            background-color: $offWhite;
            top: 25%;
            left: 25%;
            border-radius: 8px;
            text-align: center;

            .sketchbookImgWrapper {

                display: flex;
                width: 50%;
                height: 100%;

                img {

                    object-fit: cover;
                    width: 100%;
                    border-radius: 8px 0 0 8px;

                }

            }

            .sketchbookInfosWrapper {

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 100%;

                h3 {
                    color: $offBlack;
                }

                h1 {

                    text-transform: uppercase;
                    color: $dark-green;

                }

                a {

                    font-weight: bold;
                    background-color: $orange;
                    text-decoration: none;
                    color: white;
                    padding: 10px 25px;
                    text-transform: uppercase;
                    border-radius: 5px;
                    position: absolute;
                    bottom: 30px;

                }

            }

            span {

                display: flex;
                width: 35px;
                height: 35px;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
                top: 10px;
                padding: 10px;
                background-color: $orange;
                border-radius: 50px;
                cursor: pointer;
                font-weight: 700;
                font-size: 20px;
                
            }

        }

    }

}

#hero-section {
    
    padding-top: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hero-wrapper {

        max-width: 95vw;

    }

    .hero-title {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        width: 65vw;

        h1 {
            color: $offWhite;
            font-family: 'Bebas Neue', sans-serif;
            font-size: 4.75rem;
            letter-spacing: .85rem;
            cursor: default;
            user-select: none;
            text-align: center;
        }

        img {
            height: 175px;
        }
    }

    .hero-data {
        background-color: $light-green;
        height: 40px;
        color: $offWhite;
        font-size: 1.75rem;
        margin: 0 auto;
        margin-top: 2rem;
        text-align: center;
        transition: 300ms ease-in-out;
        white-space: nowrap;
        width: 470px;
        animation: typing 4s steps(26, end) alternate;
        overflow: hidden;

        &:hover {
            letter-spacing: .2rem;
        }
    }
}

#services-section {
    align-items: center;
    justify-content: space-between;
    margin: 1.35rem auto 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    h2 {
        padding: 0;
        color: $light-green;
        font-family: 'Bebas Neue', sans-serif;
        font-size: 2.5rem;
        text-shadow: 2px 1px 3px rgba(20, 20, 20, 0.8);
        letter-spacing: .3rem;
        text-align: center;
    }

    a {
        text-decoration: none;
        color: $offWhite;
        text-shadow: 1px 1px 1px rgba(20, 20, 20, 0.8);
        background-color: $light-green;
        padding: 0 1rem;
        font-family: 'Bebas Neue', sans-serif;
        font-size: 2.5rem;
        letter-spacing: .3rem;
        text-align: center;
        border-radius: 5px;
    }

    #bottomText {

        background-color: #F2F2F2;
        text-shadow: none;
        padding: 0 3rem;
        margin: 2rem 0;

    }

    .service-wrapper {

        margin: 0 5rem;
        margin-top: .5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 4rem;
        gap: 0.5rem;
        
        a {
            text-decoration: none;
        }
        
        .service-card {

            width: 200px;
            height: 295px;
            padding: 0;
            margin: 0 4px;
            align-items: center;
            text-align: center;
            justify-content: center;
            flex-direction: column;
            display: flex;
            cursor: pointer;
            background-color: $light-green;
            border-radius: 5px;
            transition: .3s ease-in-out;

            &:hover {

                filter: none;
                transform: scale(1.10);
                transition: .3s ease-in-out;
                box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

            }

            &:hover>img {

                filter: none;

            }

            img {

                padding-top: 1rem;
                height: 170px;
                filter: opacity(.85);
                transition: 400ms ease-in-out;

            }

            h3 {
                color: $offWhite;
                font-size: 2.2rem;
                font-family: 'Bebas Neue', sans-serif;
                letter-spacing: .32rem;
            }

            h4 {
                color: $offWhite;
                font-weight: 200;
                text-align: center;
                text-transform: uppercase;
                padding: 5px;
                font-weight: 500;
            }

            h5{
                color: $offWhite;
                text-transform: uppercase;
                letter-spacing: .05rem;
                .startPrice{
                    letter-spacing: .09rem;
                }
            }
            
        }

    }

    .paperTypeInfos {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {

            padding: 0;
            color: #72A67F;
            font-family: "Bebas Neue", sans-serif;
            font-size: 2.5rem;
            text-shadow: 2px 1px 3px rgba(20, 20, 20, 0.8);
            letter-spacing: 0.3rem;
            text-align: center;

        }

        a {
                
            width: 20%;
            margin: .8rem 0;
            padding: .5rem 1rem;
            border: none;
            background-color: $light-green;
            border-radius: 5px;
            cursor: pointer;
            transition: .2s ease-in-out;
            color: $offWhite;
            font-weight: bold;
            font-size: 1.25rem;
            text-decoration: none;
            text-align: center;
            text-decoration: none;

            &:hover {

                opacity: 0.9;

            }
        }

    }

}

#section-showcase {

    display: flex;
    align-items: center;
    justify-content:center;
    width: 100vw;
    height: 70vh; //mudar
    margin: 1rem 0;

    .showcase-wrapper {

        width: 100%;
        height: 100%;
        display: flex;
        background-color: $orange;
        align-items: center;
        justify-content: center;

        .showcase-image-wrapper {

            display: flex;
            width: 40%;
            height: 100%;

            img {

                width: 100%;
                object-fit: cover;

            }
                    
        }

        .showcase-text {

            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: $dark-green;
            padding: 0 2rem;
            color: $offWhite;

            h1 {
                padding: .25rem 0;
                color: $light-orange;
                font-family: 'Bebas Neue', sans-serif;
                font-size: 2.5rem;
                text-shadow: 2px 1px 3px rgba(29, 29, 29, 0.5);
                letter-spacing: .3rem;
            }

            h2 {
                padding: 0;
                color: $offWhite;
                font-size: 1.5rem;
                letter-spacing: .175rem;
                
                &.highlight-showcase-text {
                    font-family: 'Bebas Neue', sans-serif;
                    text-transform: uppercase;
                    font-size: 2.57rem;
                    letter-spacing:.3rem;
                    background: linear-gradient(
                        to right, 
                        $yellow-green, 
                        $light-blue
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                background-image: linear-gradient(
                    transparent, 
                    $light-orange 1px,
                );
                background-size: 33%;
                background-repeat: no-repeat;
                display: inline;
                transition: 0.5s ease-in-out;
            }
            
            h3 {
                color: $offWhite;
                padding: .25rem 0;
                letter-spacing:.32rem;
            }

            p {
                margin: 1.5rem 0;
                font-size: 1.2rem;
            }

            a {
                
                width: 20%;
                margin: .8rem 0;
                padding: .5rem 1rem;
                border: none;
                background-color: $light-green;
                border-radius: 5px;
                cursor: pointer;
                transition: .2s ease-in-out;
                color: $offWhite;
                font-weight: bold;
                font-size: 1.25rem;
                text-decoration: none;
                text-align: center;

                &:hover {

                    opacity: 0.9;

                }

            }

        }

    }

}

@keyframes typing {
    from {
        width: 0;
    }
}

@media screen and (max-width: 768px) {

    #mainHome .divModal .modalContent {

        width: 80%;
        left: 10%;
        top: 12.5%;

        .sketchbookInfosWrapper {

            h3 {
                font-size: .6rem;
                padding: 0 .5rem;
            }
                
            span {

                width: 20px;
                height: 20px;
                font-size: .7rem;
                
            }

            h1 {
                font-size: .8rem;
            }
            
            a {

                width: 35vw;
                padding: .5rem .5rem;
                font-size: .7rem;
                position: static;
                font-size: 8px;
                margin-top: 10px;

            }
        }
    }

    #hero-section {

        padding-top: 150px;

        .hero-title {

            flex-direction: column;
            padding-left: 0;
    
            h1 {
                font-size: 3.5rem;
                letter-spacing: .55rem;
            }
    
            img {
                height: 95px;
            }

        }
    
        .hero-data {

            height: 35px;
            font-size: 1.5rem;
            width: 90vw;

        }

    }
    
    #services-section {
        
        h2 {
            font-size: 2rem;
        }

        #bottomText {
            background-color: $offWhite;
            text-shadow: none;
        }

        .service-wrapper {

            margin: 1rem auto 2.5rem;
            flex-direction: column;
            
            .service-card {
                margin: 4px auto;
            }

        }

        .paperTypeInfos {

            h3 {

                width: 95vw;
    
            }

            a {
                width: 40%;
            }
        }

    }

    #section-showcase {

        height: auto;

        .showcase-wrapper {

            flex-direction: column;

            .showcase-image-wrapper {

                width: 100%;

            }

            .showcase-text {

                padding: 1rem 2rem;

                h1 {
                    padding: 0;
                    font-size: 2.5rem;
                    text-shadow: 2px 1px 3px rgba(29, 29, 29, 0.5);
                    letter-spacing: .3rem;
                }

                h2 {
                    padding: 0;
                    background-size: 86%;

                    &.highlight-showcase-text {
                        font-size: 2.3rem;
                    }
                }
                
              
                p {
                    margin: 0.7rem 0;
                }

                a {
                    
                    width: 40%;

                }

            }

        }

    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    #mainHome .divModal .modalContent {

        width: 60vw;
        left: 20%;
        top: 12.5%;

        h3 {
            word-wrap: break-word;
            padding: 0 1rem;
        }
    }

    #hero-section {

        .hero-title {

            flex-direction: column;
            padding-left: 0;
    
            h1 {
                letter-spacing: 0.55rem;
            }
    
            img {
                height: 95px;
            }

        }
    
        .hero-data {

            width: 90vw;

        }

    }
    
    #services-section {
        
        h2 {
            width: 90vw;
        }

        #bottomText {
            background-color: $offWhite;
            text-shadow: none;
        }

        .service-wrapper {

            width: 90%;
            margin: 0;
            gap:0;
            .service-card {
                margin: 12px auto;
            }

        }

        .paperTypeInfos {

            h3 {

                margin-top: 1.3rem;
                width: 90vw;
    
            }

            a {
                width: 25%;
            }
        }

    }

    #section-showcase {

        .showcase-wrapper {

            padding: 0;
            margin: 0;

            .showcase-image-wrapper {

                height: 100%;
                width: 50%;
                
                img {
                    height: 100%;
                }

            }

            .showcase-text {

                width: 70%;
                height: 100%;
                margin: 0;
                padding: 1rem 2rem;

                h1 {
                    padding: 0;
                    font-size: 2.5rem;
                }

                h2 {
                    padding: 0;
                    background-size: 86%;

                    &.highlight-showcase-text {
                        font-size: 2.3rem;
                    }
                }
                
              
                p {
                    margin: 0.7rem 0;
                    font-size: 18px;
                }

                a {
                    
                    width: 50%;

                }

            }

        }

    }
    
}