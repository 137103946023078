@import '../../global.scss';

footer {

    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
    font-size: 1rem;
    background: linear-gradient(45deg, $light-green, $dark-green);

    .footerContent {

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        margin: 10px 0;

        .footerImgWrapper {

            display: flex;
            width: 180px;
            height: 180px;
            margin-right: 2rem;

            img {

                border-radius: 200px;
    
            }

        }
        
        .footerSocialMedia {

            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            
            p {
    
                color: $offWhite;
                margin-left: .25rem;
            }

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 19vw;
                justify-content: center;
                padding: 0;

                li {
                    width: 100%;
                    padding: 0 1rem;
                    display: flex;
                    align-items: center;
                    margin: .4rem 0;

                    a {

                        color: $offWhite;
                        text-decoration: none;

                    }

                    button {
                        width: 95%;
                        padding: .3rem 1rem;
                        border: none;
                        background-color: $orange;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: .4s ease-in-out;
                        
                        a {
                            font-size: 1.5rem;
                            font-weight: 600;
                            text-align: center;
                            letter-spacing: .3rem;
                            font-family: 'Bebas Neue', sans-serif;
                            color: $offWhite;
                            text-decoration: none;

                            &:hover {
                                letter-spacing: .5rem;
                            }
                        }

                        &:hover {
                            background-color: $light-orange;
                        }

                    }
                }

            }
            
            a {
                cursor: pointer;
            }

            img {
                margin: 0;
                height: 4vh;
                transition: .2s ease-in-out;

                &:hover {
                    transform: scale(1.2)
                }
            }
        }

        .copyright {

            display: flex;
            
            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-decoration: none;
                align-items: center;

                p {
                    margin-top: .5rem;
                    color: $offWhite;
                    width: 265px;
                    text-align: center;
                }
                
                img {

                    border-radius: 0px; 
                    height: 120px;

                }
            }
        }

    }
}

@media screen and (max-width: 768px) {

    footer {

        padding: 0;
        height: auto;
        // height: 85vh;
        justify-content: flex-start;
    
        .footerContent {

            flex-direction: column;
            padding-bottom: 1rem;
            height: auto;
            align-self: flex-start;

            .footerImgWrapper {

                width: 140px;
                height: 140px;
                margin: 0;
    
                img {
                    
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }

            .footerSocialMedia {
                
                ul {
                    width: 100%; 
                }

                img {
                    height: 4vh;
                }
            }

            .copyright {
                
                a {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    margin-top: .875rem;

                    img {
                        height: 100px;
                    }

                    p {
                        width: 60%;
                    }
                }
            }


        }

    }


}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    footer {

        padding-bottom: 0;

        .footerBackgroundWrapper {

            height: 100%;

        }
    
        .footerContent {

            padding-bottom: 1rem;

            .footerImgWrapper {

                width: 140px;
                height: 140px;
                margin: 0 1rem 0 0;
    
                img {
                    
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }


            .footerSocialMedia {
                
                ul {
                    //flex-direction: row;
                    width: 100%; 
                }

                img {
                    height: 2.5vh;
                }
            }

            .copyright {
                
                a {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    margin-top: .875rem;

                    img {
                        margin: 0;
                        height: 9vh;
                    }

                    p {
                        width: 150px;
                    }
                }
            }


        }

    }

}