@import "../../global.scss";

* {
    font-family: 'Urbanist', sans-serif;
}

h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

#SectionAdmin {

    background: linear-gradient(45deg, $light-green, $dark-green);
    padding-top: 155px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ul {

        display: flex;
        flex-direction: column;

        a {

            list-style: none;
            background-color: $orange;
            margin: 10px 0;
            text-align: center;
            padding: 10px;
            border-radius: 5px;
            color: $offWhite;
            text-decoration: none;

        }

    }

}

.Admin {

    display: flex;
    flex-direction: column;
    background: linear-gradient(45deg, $light-green, $dark-green);

}

#mainRegister {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 110px;

}

.titleAdmin {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    text-align: center;
    font-size: .8rem;

}

.optionAdminPage ul {

    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    margin-bottom: 6vh;
    align-items: center;

}

.optionAdminPage a{

    color: white;
    padding: 15px;
    margin-top: 15px;
    cursor: pointer;
    background-color: $orange;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0px 0px 4px $preto;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    width: 60%;

}

.adminRegister {

    margin: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    fieldset {

        border: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

    }

    h1 {
        color: $orange;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    input {

        border: none;
        width: 100%;
        margin: .3rem 0;
        padding: .7rem 1rem;
        border-radius: 5px;
        border: 1.7px solid #e8e8e8;
        outline-color: $orange;

    }

    ::placeholder {

        color: $preto;
        opacity: .8;

    }

    fieldset {
        margin-top: 1rem;
    }
}

.buttonsFormRegister {

    width: 80%;
    margin-top: 30px;
    display: flex;
    justify-content: center;

    #enterButtonSignIn {

        color: $branco;
        padding: 1rem;
        background: $orange;
        border: 1px solid $orange;
        text-decoration: none;
        border-radius: .3rem;
        transition: .24s;
    
        &:hover {

            background-color: #fff;
            color: $orange;

        }
            
    
    }

}