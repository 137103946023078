@import "../../global.scss";

.header {
    width: 100vw;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    padding-top: .5em;
    height: auto;
    z-index: 2;

    .innerHeader {
        width: 100vw;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .postDate {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-green;
        color: $branco;
    }

    .displayNone {
        display: none;
    }

    .logo {

        a {

            img {
                height: 10vh;
                margin: 0.5rem;

            }
        }

    }

    .linkWrapper {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;

        a {

            margin: 0 .4rem;
            text-decoration: none;
            color: #fff;
            font-weight: 700;

        }
    }

    .menu {
        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            list-style: none;
            position: relative;
            z-index: 2;
            padding: 0;

            li {

                margin: 0.5rem;
                padding: 0.5rem;

                a {
                    text-decoration: none;
                    color: $dark-green;
					background-color: $offWhite;
					padding: 0.5rem;
					border-radius: 5px;
					transition: transform .45s ease, background .15s ease;

					&:hover {
						background-color: $light-green;
						color: $branco;
					}
                }
            }
        }
    }
}

.menu-mobile {
    ul {
        display: none;

        li {
            display: none;
        }

        #dropdown-custom-1 {
            display: none;
        }

        .super-colors {
            display: none;
        }
    }
}

.sandwich {

    display: none;

    input {

        opacity: 0;
    
    }

}

.header.active {
    background: linear-gradient(45deg, $light-green, $dark-green);
}

@media screen and (max-width: 1000px) {
   
    .menu {
        display: none;
    }

    .sandwich {

        display: flex;
        align-items: center;
        justify-content: center;

        input {
            opacity: 100;
        }

        #checkbox {
            display: none;
        }

    }

    .postDate {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-green;
        color: $branco;
        font-size: small;
    }

    .sandwich label {

        cursor: pointer;
        position: relative;
        display: block;
        height: 5px;
        width: 50px;

    }

    .sandwich span {

        position: absolute;
        height: 3px;
        width: 30px;
        background: $offWhite;
        transition: .5s ease-in-out;

    }

    .sandwich span:nth-child(1) {

        position: absolute;
        top: -8px;
        height: 3px;
        width: 30px;
        background: $offWhite;

    }

    .sandwich span:nth-child(3) {

        position: absolute;
        top: 8px;
        height: 3px;
        width: 30px;
        background: $offWhite;

    }

    #checkbox:checked + label span:nth-child(1) {

        transform: rotate(-225deg);
        top: 0px;

    }

    #checkbox:checked + label span:nth-child(2) {

        opacity: 0;

    }

    #checkbox:checked + label span:nth-child(3) {

        transform: rotate(225deg);
        top: 0px;

    }

    .menu-mobile {

        width: 100vw;
        align-items: center;
        justify-content: center;
        flex-direction: column;
		background-color: $dark-green;
        display: none;

        ul {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            list-style: none;
            margin-top: 10em;
            margin-bottom: 0em;

            li {
                display: flex;
                margin: 0.5rem;

                a {
                    text-decoration: none;
                    color: $dark-green;
					background-color: $offWhite;
					padding: 0.5rem;
					border-radius: 5px;
					transition: transform .45s ease, background .15s ease;

					&:hover {
						background-color: $light-green;
						color: $branco;
					}
                }
            }
        }
    }
}