@import "../../global.scss";

* {

    font-family: 'Urbanist', sans-serif;

}

h1, h2, h3, h4, p {

    margin: 0;
    padding: 0;

}

#SectionSignUp {

    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;

    .textIntroSignUp {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 100%;
        position: relative;

        background-color: $orange;

        .imageLogoWrapper {

            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;
            height: 10%;

            position: absolute;
            top: 3%;
            left: 5%;

            img {

                width: 70px;
                object-fit: cover;
                background-color: white;
                border-radius: 100px;
                padding: 5px;

            }

        }

        h1 {

            margin-bottom: 2rem;
            text-transform: uppercase;
            font-size: 3.5rem;
            cursor: default;

        }

        h1, p {

            padding: 0 3rem;
            color: $branco;

        }

    }

    .signUpDiv {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        height: 100%;

        .formRegister {

            display: flex;
            flex-direction: column;
            width: 60%;

            input, select {

                margin: .2rem .2rem;
                padding: .7rem 1rem;
                border-radius: 5px;

                border: 1.7px solid #e8e8e8;
                outline-color: $orange;

            }

            .passwordDiv {

                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;

                input {

                    width: 50%;

                }

            }

            .cityDiv {

                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;

                select {

                    width: 20%;

                }

                input {

                    width: 80%;

                }

            }

            button {

                align-self: center;
                border: none;
                background-color: $orange;
                color: $branco;
                font-weight: 700;
                width: 40%;
                padding: 15px 0;
                margin-top: 10px;
                border-radius: 6px;
                cursor: pointer;

            }

            .legalTermsWrapper {

                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                text-align: center;
                align-self: center;
                margin-top: 1rem;

            }

        }

    }

}

@media screen and (max-width: 768px) {

    #SectionSignUp {

        flex-direction: column;
        height: auto;

        .textIntroSignUp {

            width: 100%;
            margin-bottom: 2rem;

            .imageLogoWrapper {

                position: static;
                margin: 2rem 0 1rem;

            }

        }

        .signUpDiv {

            width: 100%;
    
            .formRegister {
    
                width: 80%;
    
                .cityDiv {
    
                    select {
    
                        width: 40%;
    
                    }
    
                    input {
    
                        width: 60%;
    
                    }
    
                }
    
                button {
    
                    margin-bottom: 2rem;
    
                }
    
            }
    
        }

    }

}
