@import '../../../global.scss';

#mainRequestAdmin {

    position: relative;
    min-height: 100vh;

}

.divModalRequests {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;

    .modalContentRequests {

        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $offWhite;
        width: 80vw;
        height: 90vh;
        top: 100%;
        left: 50%;
        margin-left: -40vw;
        margin-top: -95vh;
        overflow-x: hidden;
        overflow-y: scroll;

        .modalFirstInfo {
            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 1rem;

            h1 {

                text-decoration: underline solid $dark-green;

            }

            span {

                font-weight: 500;
                background-color: $orange;
                padding: .2rem .5rem;
                border-radius: 5px;
                margin: .5rem 0;
                color: #fff;

            }
            
        }


        h2 {

            margin-bottom: 2rem;
            background-color: $orange;
            color: #fff;
            padding: 3px 20px;
            border-radius: 100px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

        }

        .closeModalDiv {

            display: flex;
            width: 100%;
            justify-content: flex-end;

            span {

                display: flex;
                width: 35px;
                height: 35px;
                align-items: center;
                justify-content: center;
                padding: 10px;
                background-color: $orange;
                border-radius: 50px;
                cursor: pointer;
                font-weight: 700;
                font-size: 20px;
                margin: 5px 5px 5px 0;
                
            }

        }

        .userInfosWrapper {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .userData {

                display: flex;
                margin: 10px 6px;
                background-color: $orange;
                color: #fff;
                padding: 3px 10px;
                border-radius: 100px;

                b {

                    margin-left: 6px;

                }

            }

        }

        .productModalInfos {

            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;

            .productCard {

                display: flex;
                justify-content: center;
                list-style: none;
                flex-direction: column;
                background-color: $dark-green;
                min-height: 200px;
                min-width: 25%;
                max-width: 50%;
                border-radius: 5px;
                margin: 10px;
                padding: 10px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                h4 {

                    margin-bottom: 1rem;
                    align-self: center;
                    background-color: $light-green;
                    color: #fff;
                    padding: 0 10px;
                    border-radius: 50px;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                }

                .productData {

                    font-weight: 500;

                }

                #coverColor {

                    display: flex;

                    span {

                        margin-left: 6px;

                    }

                }

                #productValue {

                    margin-top: 1rem;
                    align-self: center;
                    background-color: $light-green;
                    color: #fff;
                    padding: 0 10px;
                    border-radius: 50px;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                }

            }

        }

    }

}

#MainRequests {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;

    .filterNameSearch {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        h3, p {  

            text-align: center;
            color: #fff;
            text-align: center;

        }
    }
    
    .searchUsername {

        display: flex;
        align-items: center;
    
        input {
            width: 30vw;
            height: 40px;
            border: none;
            // text-align: center;
            font-size: .9rem;
            margin-top: 1rem;
            border-radius: 5px;
            background-color: $dark-green;
            outline: none;
            color: white;
            padding-left: 15px;
        }
    
        ::placeholder {
            color: white;
        }
    }
    
    .divSearchUsernameResult {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
    
        a {
            display: flex;
            color: $branco;
            padding: 15px;
            margin-top: .5rem;
            margin-bottom: 1.5rem;
            cursor: pointer;
            background-color: $orange;
            border-radius: 10px;
            justify-content: center;
            font-weight: 700;
        }
    
    }

    .selectWrapper {

        display: flex;
        width: 70vw;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;

        h3 {
    
            margin: 0 0 .5rem 0;
            text-align: center;
            color: #fff;

        }

        .finishOrder {

            display: flex;
            flex-direction: column;
    
            .finishOrderWrapper {
    
                display: flex;
                align-items: center;
    
                select {
    
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    padding: .5rem .2rem;
    
                }
    
                button {
    
                    margin-left: 10px;
                    padding: .5rem .4rem;
                    border-radius: 5px;
                    outline: none;
                    border: none;
                    background-color: $orange;
                    color: #fff;
                    font-weight: 700;
                    cursor: pointer;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
                }
    
            }
    
        }

        .selectPeriodChangeDiv {

            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
    
            select {
    
                width: 70%;
                outline: none;
                border: none;
                border-radius: 5px;
                padding: .5rem .2rem;
    
            }
    
        }

    }

    #resultSearch {

        margin-top: 2rem;

    }

    .boxOrderWrapper {

        display: flex;
        flex-direction: column-reverse;
        width: 80%;

        .boxOrder {

            display: flex;
            flex-direction: column;
            padding: 25px;
            width: 100%;
            background-color: $orange;
            border-radius: 15px;
            margin: 2em auto;
            align-items: center;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            h1 {

                text-align: center;
                color: #fff;
                text-decoration: underline solid $dark-green;

            }

            span {

                text-align: center;
                color: #fff;
                font-weight: 500;

            }

            h5 {

                margin: 1rem 0 2rem;

            }

            .infosWrapper {

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                width: 100%;

                .userInfosWrapper {

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 33%;

                    #status {

                        margin-bottom: 1rem;
                        text-align: center;
        
                    }

                    button {

                        margin-bottom: 1rem;
                        background-color: $dark-green;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                        padding: 5px 15px;
                        border: none;
                        border-radius: 50px;
                        color: #fff;
                        font-weight: 700;
                        cursor: pointer;
                        font-size: 16px;

                    }

                    .rowItens {

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 10px 0;
                        text-align: center;

                    }

                    .requestStatus {

                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;

                        select, button {

                            padding: .5rem .2rem;
                            margin: 5px;

                        }

                        select {

                            width: 50%;
                            outline: none;
                            border: none;
                            border-radius: 5px;

                        }

                        button {

                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            border: none;
                            background-color: $dark-green;
                            color: #fff;
                            text-align: center;
                            cursor: pointer;
                            transition: .2s ease-in-out;
                            font-size: 16px;
                            font-weight: 700;
                
                            &:hover {
                
                                background-color: $offWhite;
                                color: $dark-green;
                
                            }

                        }

                    }

                    .clientMessage, .trackingCode {

                        display: flex;
                        flex-direction: column;
                        margin-top: 1rem;
                        align-items: center;
                        width: 100%;
            
                        input {
            
                            padding: 10px;
                            width: 100%;
                            border-radius: 5px;
                            color: $orange;
                            background-color: $offWhite;
                            border: none;
                            outline: none;
            
                        }
            
                        button {
            
                            margin: 1rem 0;
                            padding: 10px 20px;
                            border-radius: 5px;
                            border: none;
                            background-color: $dark-green;
                            color: #fff;
                            text-align: center;
                            margin: 10px;
                            cursor: pointer;
                            transition: .2s ease-in-out;
                            font-size: 16px;
                            font-weight: 700;
                
                            &:hover {
                
                                background-color: $offWhite;
                                color: $dark-green;
                
                            }
            
                        }

                        .adminNoteDiv {

                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            width: 100%;

                        }
            
                    }

                }

            }

        }

    }

}

@media screen and (max-width: 768px) {

    .divModalRequests {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 3;
    
        .modalContentRequests {
           
            margin-top: -95vh;
    
            .modalFirstInfo {
                
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-bottom: 1rem;
    
                h1 {
    
                    text-decoration: underline solid $dark-green;
    
                }
    
                span {
    
                    font-weight: 500;
                    background-color: $orange;
                    padding: .2rem .5rem;
                    border-radius: 5px;
                    margin: .5rem 0;
                    color: #fff;
    
                }
                
            }
    
    
            h2 {
    
                margin-bottom: 2rem;
                background-color: $orange;
                color: #fff;
                padding: 3px 20px;
                border-radius: 100px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
            }
    
            .closeModalDiv {
    
                display: flex;
                width: 100%;
                justify-content: flex-end;
    
                span {
    
                    display: flex;
                    width: 35px;
                    height: 35px;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    background-color: $orange;
                    border-radius: 50px;
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 20px;
                    margin: 5px 5px 5px 0;
                    
                }
    
            }
    
            .userInfosWrapper {
    
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
    
                .userData {
    
                    display: flex;
                    margin: 10px 6px;
                    background-color: $orange;
                    color: #fff;
                    padding: 3px 10px;
                    border-radius: 100px;
    
                    b {
    
                        margin-left: 6px;
    
                    }
    
                }
    
            }
    
            .productModalInfos {
    
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: center;
    
                .productCard {
    
                    min-height: 200px;
                    min-width: 75%;
                    min-width: 85%;
    
                    h4 {
    
                        margin-bottom: 1rem;
                        align-self: center;
                        background-color: $light-green;
                        color: #fff;
                        padding: 0 10px;
                        border-radius: 50px;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
                    }
    
                    .productData {

                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        font-weight: 500;
                        margin: 10px 0;
    
                    }
    
                    #coverColor {
    
                        display: flex;
                        flex-direction: column;
                        text-align: center;
    
                        span {
    
                            margin: 0;
    
                        }
    
                    }
    
                    #productValue {
    
                        margin-top: 1rem;
                        align-self: center;
                        background-color: $light-green;
                        color: #fff;
                        padding: 0 10px;
                        border-radius: 50px;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
                    }
    
                }
    
            }
    
        }
    
    }
    
    #MainRequests {

        .divSearchUsernameResult {
    
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: white;
        
            a {
                display: flex;
                color: $branco;
                padding: 15px;
                margin-top: .5rem;
                margin-bottom: 1.5rem;
                cursor: pointer;
                background-color: $orange;
                border-radius: 10px;
                justify-content: center;
                font-weight: 700;
            }
        
        }

        .searchUsername input {

            width: 70vw;

        }
    
        .selectWrapper {
    
            display: flex;
            flex-direction: column;
    
            .finishOrder {
    
                display: flex;
                flex-direction: column;
        
                .finishOrderWrapper {
        
                    display: flex;
                    align-items: center;
        
                    select {
        
                        outline: none;
                        border: none;
                        border-radius: 5px;
                        padding: .5rem .2rem;
        
                    }
        
                    button {
        
                        margin-left: 10px;
                        padding: .5rem .4rem;
                        border-radius: 5px;
                        outline: none;
                        border: none;
                        background-color: $orange;
                        color: #fff;
                        font-weight: 700;
                        cursor: pointer;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        
                    }
        
                }
        
            }
    
            .selectPeriodChangeDiv {
    
                margin-top: 1rem;
        
                select {
        
                    width: 70%;
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    padding: .5rem .2rem;
        
                }
        
            }
    
        }
    
        #resultSearch {
    
            margin-top: 2rem;
    
        }
    
        .boxOrderWrapper {
    
            display: flex;
            flex-direction: column-reverse;
            width: 80%;
    
            .boxOrder {
    
                display: flex;
                flex-direction: column;
                padding: 25px;
                width: 100%;
                background-color: $orange;
                border-radius: 15px;
                margin: 2em auto;
                align-items: center;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
                h1 {
    
                    text-align: center;
                    color: #fff;
                    text-decoration: underline solid $dark-green;
    
                }
    
                span {
    
                    text-align: center;
                    color: #fff;
                    font-weight: 500;
    
                }
    
                h5 {
    
                    margin: 1rem 0 2rem;
    
                }
    
                .infosWrapper {
    
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    width: 100%;
    
                    .userInfosWrapper {
    
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: auto;
    
                        button {
    
                            margin-bottom: 1rem;
                            background-color: $dark-green;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                            padding: 5px 15px;
                            border: none;
                            border-radius: 50px;
                            color: #fff;
                            font-weight: 700;
                            cursor: pointer;
                            font-size: 16px;
    
                        }
    
                        .rowItens {
    
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin: 10px 0;
                            text-align: center;
    
                        }
    
                        .requestStatus {
    
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: center;
    
                            select, button {
    
                                padding: .5rem .2rem;
                                margin: 5px;
    
                            }
    
                            select {
    
                                width: 50%;
                                outline: none;
                                border: none;
                                border-radius: 5px;
    
                            }
    
                            button {
    
                                padding-left: 10px;
                                padding-right: 10px;
                                border-radius: 5px;
                                border: none;
                                background-color: $dark-green;
                                color: #fff;
                                text-align: center;
                                cursor: pointer;
                                transition: .2s ease-in-out;
                                font-size: 16px;
                                font-weight: 700;
                    
                                &:hover {
                    
                                    background-color: $offWhite;
                                    color: $dark-green;
                    
                                }
    
                            }
    
                        }
    
                        .clientMessage {
    
                            display: flex;
                            flex-direction: column;
                            margin-top: 1rem;
                            align-items: center;
                            width: 100%;
                
                            input {
                
                                padding: 10px;
                                width: 100%;
                                border-radius: 5px;
                                color: $orange;
                                background-color: $offWhite;
                                border: none;
                                outline: none;
                
                            }
                
                            button {
                
                                margin: 1rem 0;
                                padding: 10px 20px;
                                border-radius: 5px;
                                border: none;
                                background-color: $dark-green;
                                color: #fff;
                                text-align: center;
                                margin: 10px;
                                cursor: pointer;
                                transition: .2s ease-in-out;
                                font-size: 16px;
                                font-weight: 700;
                    
                                &:hover {
                    
                                    background-color: $offWhite;
                                    color: $dark-green;
                    
                                }
                
                            }
    
                            .adminNoteDiv {
    
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                                width: 100%;
    
                            }
                
                        }
    
                    }
    
                }
    
            }
    
        }
    
    }

}