@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700;900&family=Source+Sans+Pro:wght@400;700;900&display=swap');

@import '../../global.scss';

#Blog {

    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;

    #mainBlog {
    
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow-x: hidden;
        max-width: 100vw;
        width: 100vw;
        padding: 20vh 0;
        // background: #fff;

        .highlightWrapper {

            display: flex;
            align-items: center;
            width: 80vw;
            height: 75vh;
            background-color: $offWhite;
            border-radius: 5px;

            .textDiv {

                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 45%;
                height: 80%;
                padding: 2rem;

                h1 {

                    font-size: 2rem;
                    font-family: 'Nunito', sans-serif;
                    font-weight: 900;
                    color: #3d3d3d;

                }

                p {

                    color: #726e6d;
                    margin: 1rem 0;
                    line-height: 1.5rem;

                }

                a {

                    text-decoration: none;
                    background-color: $orange;
                    width: 50%;
                    padding: 10px 25px;
                    border-radius: 5px;
                    text-align: center;
                    color: #fff;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;

                }

            }

            .illustrationDiv {

                display: flex;
                align-items: center;
                justify-content: center;
                width: 55%;
                height: 100%;

                img {

                    width: 70%;
                    object-fit: cover;

                }

            }
            

        }

        .cardsWrapper {

            display: flex;
            width: 90%;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-top: 4rem;

            .blogCard {

                width: 30%;
                height: 420px;
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 20px;
                background-color: $offWhite;
                border-radius: 10px;
                transition: all .25s cubic-bezier(.02,.01,.47,1);
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                text-decoration: none;

                .blogImgWrapper {

                    display: flex;
                    width: 100%;
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;
                    height: 220px;
                    margin-bottom: 20px;
                    transform: translateZ(0);
                    position: relative;

                    .authorPictureWrapper {

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        width: 50px;
                        height: 50px;
                        border-radius: 100px;
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        z-index: 2;
                        background-color: #fff;

                        img {

                            width: 100%;
                            object-fit: cover;
                            padding: 2px;
                            border-radius: 100px;

                        }
                        
                    }

                    img {

                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;

                    }

                }

                .blogTextWrapper {

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 1rem;
                    width: 100%;

                    .hashtagWrapper {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-bottom: 1rem;

                        span {

                            margin: 2px 5px;
                            letter-spacing: .2px;
                            line-height: 1.3;
                            font-size: .875rem;
                            font-weight: 600;
                            background-color: $orange;
                            padding: 0 7px;
                            border-radius: 100px;
                            color: #fff;

                        }

                    }

                    #postDate, h3 {

                        margin: 5px 0;
                        font-family: "Poppins", sans-serif;
                        
                    }

                    #postDate {

                        color: #585858;
                        font-size: 14px;

                    }

                    // p {

                    //     display: -webkit-box;
                    //     -webkit-line-clamp: 3;
                    //     -webkit-box-orient: vertical;
                    //     overflow: hidden;
                    //     text-overflow: ellipsis;
                        
                    // }

                    h3 {

                        color: #1c1d21;
                        font-family: "Source Sans Pro", "Helvetica", "Arial", sans-serif;
                        letter-spacing: .4px;
                        line-height: 1.3;
                        font-size: 1.25rem;
                        font-weight: 600;
                        margin: 0;

                    }

                }

                &:hover {

                    transform: translate(0px, -10px);
                    transition: ease 0.5s;

                }

            }

        }

    }

}

@media screen and (max-width: 768px) {

    #Blog #mainBlog {

        padding: 20vh 0 5vh;

        .highlightWrapper {

            flex-direction: column;
            height: 100%;

            .textDiv {
                width: 100%;

                h1 {
                    font-size: 1.5rem;
                    margin-bottom: .5rem;
                }

                a {
                    width: 100%;
                    margin: 1rem 0;
                }

            }

            .illustrationDiv {
                width: 100%;
                margin-bottom: 2rem;
            }

        }

        .cardsWrapper {

            flex-direction: column;
            margin-top: 2rem;

            .blogCard {
                width: 90%;
            }

        }

    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    #Blog #mainBlog {

        padding: 16vh 0 5vh;

        .highlightWrapper {

            height: 100%;

            .textDiv {
                width: 100%;

                h1 {
                    font-size: 1.5rem;
                    margin-bottom: .5rem;
                }

                a {
                    width: 100%;
                    margin: 1rem 0;
                }

            }

            .illustrationDiv {
                width: 100%;
            }

        }

        .cardsWrapper {

            //flex-direction: column;
            margin-top: 2rem;

            .blogCard {
                width: 40%;
            }

        }

    }

}