@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import '../../../global.scss';

* {

       margin: 0;
       padding: 0;
       font-family: 'Montserrat', sans-serif;
       box-sizing: border-box;

}

main {

       width: 100vw;
       display: flex;
       flex-direction: column;
       background: linear-gradient(45deg, #72A67F, #24733F);

       #VoucherSection {

              display: flex;
              margin-top: 8rem;
              flex-direction: column;
              align-items: center;
              color: #fff;

              h1 {

                     margin: 1rem 0;
                     padding: .3rem 1rem;
                     background-color: $orange;
                     border-radius: 100px;

              }

              input{
                     margin-top: 0.5rem;
                     margin-bottom: 2rem;
                     width: 20%;
                     height: 40px;
              }

              input[type=text] {

                     margin-bottom: 2rem;
                     width: 30%;
                     height: 40px;
                     padding: .7rem .5rem;
                     border-radius: 5px;

                     border: 1.7px solid #e8e8e8;

              }

              button {

                     padding: .7rem 2rem;
                     margin-bottom: 2rem;
                     background-color: $orange;
                     border: none;
                     cursor: pointer;
                     color: #fff;
                     font-size: 16px;
                     font-weight: 600;
                     border-radius: 5px;
                     transition: .2s ease-in-out;

                     &:hover {

                            background-color: $light-orange;

                     }

              }


       }

       #deleteVoucherSection {

              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              margin-top: 2rem;

              fieldset {

                     display: flex;
                     align-items: center;
                     flex-direction: column;
                     outline: none;
                     border: none;
                     text-align: center;
                     width: 100%;

                     legend h1 {

                            margin: 1rem 0;
                            padding: .3rem 1rem;
                            background-color: $orange;
                            color: #fff;
                            border-radius: 100px;

                     }

                     select {

                            margin: .2rem .2rem;
                            padding: .7rem 0;
                            border-radius: 5px;
                            width: 30%;

                            border: 1.7px solid #e8e8e8;
                            outline-color: $orange;

                     }

                     button {

                            padding: .7rem 2rem;
                            margin: 1rem 0 2rem;
                            background-color: $orange;
                            border: none;
                            cursor: pointer;
                            color: #fff;
                            font-size: 16px;
                            font-weight: 600;
                            border-radius: 5px;
                            transition: .2s ease-in-out;

                            &:hover {

                                   background-color: $light-orange;

                            }
                     }

              }

       }

}

@media screen and (max-width: 768px) {

       main {

              #VoucherSection, #deleteVoucherSection {
                     input {
                            width: 50%;
                     }
                     input[type=text] {
                            width: 50%;
                     }
                     fieldset{
                            select {
                                   width: 60%;
                            }
                     }
                     

                     text-align: center;

              }

       }

}