@import "../../global.scss";

.profilePage {

    display: flex;
    flex-direction: column;
    width: 100vw;
    background: linear-gradient(45deg, $light-green, $dark-green);

    #DataSection {

        margin-top: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {

            font-family: 'Bebas Neue', sans-serif;
            font-size: 2.5rem;
            color: $light-green;
            text-shadow: 2px 1px 3px rgba(29, 29, 29, 0.5);
            letter-spacing: .3rem;
            text-align: center;

        }

        .links {

            display: flex;

            a {

                margin: 1rem .5rem;
                padding: .5rem 1rem;
                border-radius: 5px;
                outline: none;
                border: none;
                background-color: $orange;
                color: #fff;
                font-weight: 700;
                cursor: pointer;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                text-decoration: none;
    
            }

        }

        .userData {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100vw;

            .boxInfoUser {

                display: flex;
                justify-content: space-between;
                width: 80vw;
                padding: 1rem .2rem;
                background-color: $light-green;
                border-radius: 5px;
                margin: .6rem 0;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                h4 {

                    color: $branco;
                    padding: 0 5px;

                }

            }

            .buttonsWrapper {

                display: flex;
                margin: 2rem 0;

                button {

                    margin: 0 8px;
                    background-color: $orange;
                    color: $branco;
                    padding: 1rem 4rem;
                    transition: transform .45s ease, background .15s ease;
                    cursor: pointer;
                    border: none;
                    border-radius: 5px;
                    font-weight: 700;
    
                }

                .deleteButton {

                    background-color: rgb(172, 23, 23);
            
                }

            }

        }

    }

}

@media screen and (max-width: 768px) {

    .buttonsWrapper {

        flex-direction: column;

        button {

            margin: 10px 0 !important;

        }

    }

}