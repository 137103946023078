@import "../../global.scss";

* {
    font-family: 'Urbanist', sans-serif;
}

h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

#contact-body {
    background: linear-gradient(45deg, $light-green, $dark-green);
    padding-top: 155px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1, p {
        color: $offWhite;
        text-align: center;
        width: 80%;
    }

    .contact-form {
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem;

        input, textarea {
            width: 50%;
            margin: .3rem 0;
            padding: .7rem 1rem;
            border-radius: 5px;
            border: 1.7px solid #e8e8e8;
            outline-color: $orange;
            background: $offWhite;
        }

        .button-contact {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;

            input {
                background-color: $dark-green;
                color: $offWhite;
                transition: .2s ease-in-out;
                cursor: pointer;
                border: none;

                &:hover {
                    background-color: $offWhite;
                    color: $dark-green;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #contact-body {
        .contact-form {
            input, textarea {
                width: 100%;
                padding: 0.8rem;
            }
        }
    }
}